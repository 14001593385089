'use client';

import { useTranslations } from 'next-intl';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
const contactSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email address').nonempty('Email is required'),
  phoneNumber: z.string().optional(),
  message: z.string().min(1, 'Message is required')
});
type ContactFormInputs = z.infer<typeof contactSchema>;
export function Contact() {
  const [isSent, setIsSent] = useState(false);
  const t = useTranslations('Contact');
  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<ContactFormInputs>({
    resolver: zodResolver(contactSchema)
  });
  const sendEmail = (data: ContactFormInputs) => {
    emailjs.send('service_mczhwcg',
    // Service ID
    'template_kaegqlc',
    // Template ID
    {
      ...data
    }, 'wgG-nrNVtnBgBw9FZ' // Public Key
    ).then(() => {
      toast.success('Form was sent!');
      setIsSent(true);
    }).catch(() => {
      toast.error('Error during sending message');
    });
  };
  return <section id="contact" aria-label="Contact" data-sentry-component="Contact" data-sentry-source-file="Contact.tsx">
      <div className="relative isolate bg-gray-900">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-6xl">
                {t('get-in-touch')}
              </h2>
              <p className="mt-6 text-lg/8 text-gray-300">{t('sub-text')}</p>
            </div>
          </div>
          <form onSubmit={handleSubmit(sendEmail)} className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-semibold text-white">
                    {t('first-name')}
                  </label>
                  <input id="firstName" {...register('firstName')} className={`mt-2 block w-full rounded-md bg-white/5 px-3.5 py-2 text-base text-white placeholder:text-gray-500 focus:outline-indigo-500 ${errors.firstName ? 'border-2 border-red-600 outline-red-600' : 'border-transparent'}`} />
                  {errors.firstName && <p className="mt-1 text-sm text-red-500">
                      {errors.firstName.message}
                    </p>}
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-semibold text-white">
                    {t('last-name')}
                  </label>
                  <input id="lastName" {...register('lastName')} className={`mt-2 block w-full rounded-md bg-white/5 px-3.5 py-2 text-base text-white placeholder:text-gray-500 focus:outline-indigo-500 ${errors.lastName ? 'border-2 border-red-600 outline-red-600' : 'border-transparent'}`} />
                  {errors.lastName && <p className="mt-1 text-sm text-red-500">
                      {errors.lastName.message}
                    </p>}
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-semibold text-white">
                    {t('company-email')}
                  </label>
                  <input id="email" {...register('email')} className={`mt-2 block w-full rounded-md bg-white/5 px-3.5 py-2 text-base text-white placeholder:text-gray-500 focus:outline-indigo-500 ${errors.email ? 'border-2 border-red-600 outline-red-600' : 'border-transparent'}`} />
                  {errors.email && <p className="mt-1 text-sm text-red-500">
                      {errors.email.message}
                    </p>}
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="phoneNumber" className="block text-sm font-semibold text-white">
                    {t('phone-number')}
                  </label>
                  <input id="phoneNumber" {...register('phoneNumber')} className={`mt-2 block w-full rounded-md bg-white/5 px-3.5 py-2 text-base text-white placeholder:text-gray-500 focus:outline-indigo-500 ${errors.phoneNumber ? 'border-2 border-red-600 outline-red-600' : 'border-transparent'}`} />
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block text-sm font-semibold text-white">
                    {t('message')}
                  </label>
                  <textarea id="message" rows={4} {...register('message')} className={`mt-2 block w-full rounded-md bg-white/5 px-3.5 py-2 text-base text-white placeholder:text-gray-500 focus:outline-indigo-500 ${errors.message ? 'border-2 border-red-600 outline-red-600' : 'border-transparent'}`} />
                  {errors.message && <p className="mt-1 text-sm text-red-500">
                      {errors.message.message}
                    </p>}
                </div>
              </div>
              <div className="mt-8 flex justify-end">
                {!isSent && <button type="submit" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-indigo-400 focus:outline-indigo-500">
                    {t('send-message')}
                  </button>}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>;
}